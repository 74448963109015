module.exports = {
    home: {
        title: 'CONGRATS! YOU WIN THE PRIZE!'
    },
    landing: {
        warranty: '24 months warranty'
    },
    redeem: {
        tip1: 'Note: Every customer can only redeem once.\n' +
            'Our company reserves the right of final explanation.',
        tip2: 'Let\'s get started now.\n' +
            'Please enter your redeem code: ',
        code: 'redeem code: ',
        codeAlert: 'Please enter the correct redeem code.',
    },
    warranty: {
        name: 'Name:',
        nameAlert: 'Name must not exceed 32 characters.',
        email: 'Email:',
        emailAlert: 'Email address must not exceed 64 characters.',
        emailAlert2: 'Please enter a valid email addressPlease enter a valid email address.',
        order: 'Amazon order number:',
        orderTip: 'Example：249-1234567-1234567（17 characters）',
        orderAlert: 'Please enter your correct Amazon order number.',
        market: 'Purchase from:',
        otherContactTip: 'Kindly note that we will contact you by email, but the email may be rejected or enter the spam box, so please leave at least one of the following alternate contact methods. Your personal information will be kept confidential.',
        line: 'LINE ID：',
        lineTip: '',
        facebook: 'Facebook ID：',
        facebookTip: '',
        twitter: 'Twitter ID：',
        twitterTip: '',
        whatsapp: "WhatsApp ID：",
        whatsappTip: '',
        otherContact: 'Other ways to contact:',
        otherContactAlert: 'Please provide at least one more contact information.',
        // warningTip: 'Please note: We do not share the above personal information with anyone other than us. Please don\'t worry about it.'
    },
    finished: {
        title1: 'Thank you very much for taking the time to fill out this form.\n\nOnce your review is reflected on the Amazon product page, we will send you an Amazon gift code. It takes about 2-7 days.\n\nPlease note: Customers who received a gift are not eligible. Please understand that this is not a gift for customers who received a gift.',
        title2: 'Thank you so much for your cooperation!\n\nOnce your review is reflected on the Amazon product page, we will send you an Amazon gift code. It takes about 2-7 days.\nIn the unlikely event that you do not receive the e-mail, please check to see if the e-mail address you entered is incorrect or if it goes into your junk mail.\n\nWe look forward to your continued patronage of our company.',

    },
    trial: {
        title: 'For your information, we occasionally hold free new trial campaigns, so please select the free trial product(s) you are interested in from the list below. (Multiple choice)',
        trialAlert: 'Choose at least one',
        submit: 'I am interested in the above products',
        cancel: 'I\'m not interested in all of them.'
    },
    trialFinished: {
        title: 'Thank you so much for your cooperation!',
        tip: 'We will contact you after the free trial campaign you are interested in holds.\nWe will guide you through the specific process separately\n' +
            '\n' +
            'In the unlikely event that you do not receive the e-mail, please check whether the e-mail address you entered is correct or not.\nPlease check to see if it goes into your junk mail.\n\n' +
            'We look forward to your continued patronage of our company.'
    },
    survey: {
        title: 'Are you satisfied with our product?',
        option1: 'Yes',
        option2: 'No',
        dissatisfiedAlert: 'Please select whether you are satisfied with your purchase.',
        reportTip: 'Please tell us the problem you meet from our product\nYour valuable opinion will help us improve the product quality.\nAlso, we stand behind our products and will try our best to solve your case.',
        reportAlert: 'If you have any problems, please do not hesitate to let us know.'
    },
    review: {
        title: 'It is our honor',
        reviewTip1: 'Could you kindly spend a bit time to write a review for our product? And then enclose the screenshot of your review in the attachment. Once your review is live on Amazon, we will send you the gift card within 3 days.\n',
        reviewTip1_1: 'Here are the steps:\n①Open Amazon and log in;\n②Click “Your orders”;\n③Find the order of our product and then click “Write a product review”;\n④Rate 5 star and add a written review with photo or video. ',
        reviewWarning: 'please note:',
        reviewWarning1: 'Please do not include a photo of this scratch card in the review.',
        reviewWarning2: 'The screenshot you attach should be the review page rather than a product photo.',
        reviewExampleTip: 'Below is a example screenshot of your review:',
        // reviewTip2: 'ご注意：スクリーンショットは製品の写真ではなく、製品のレビュー内容をご提出ください。',
        // reviewTip3: 'レビュー内容の中にスクラッチカードの写真を添付しないでください。',
        // reviewTip4: '最近、投稿したレビューが削除されてしまうことがあるので、アカウントの安全を保護するため、商品を受け取ったら一週間の程で使用感想をアマゾンでレビューを投稿お願い申し上げます。',
        imageTip: 'Attach your screenshot here. :)',
        imageAlert: 'Do not submit only product photos. We need a screenshot of the product review.',
        dialog: {
            title: 'Leave a Note',
            tip1: 'Please check {0} for screenshots, not pictures of the product.',
            tip2: 'Product Review Content',
            cancel: 'No, submit again',
            submit: 'Yes'
        }
    },
    reviewFinished: {
        title: 'Thank you so much for your cooperation!',
    },
    surveyFinished: {
        title: 'Thank you so much for your cooperation!',
        dissatisfied: 'Thank you very much for taking the time to fill out this form.\n' +
            'We will fully review your proposal internally and will contact you again.\n' +
            '\n' +
            'In the unlikely event that you do not receive the e-mail, please check whether the e-mail address you entered is correct or not.\n' +
            'Please check to see if it goes into your junk mail.\n' +
            '\n' +
            'We look forward to your continued patronage of our company.'
    },
    alert: {
        90001: 'This Amazon order number has already been used, please provide another Amazon order number.',
        90002: 'Please choose the correct marketplace.',
        90003: 'This order number has already been submitted. Please wait a few more days while we review your order. Once your review is reflected on the Amazon product page, we will send you the Amazon gift code within 2-7 days. Please be patient.',
        90004: 'Sorry, only one redemption per person on the same account. Since you have already redeemed your Amagif, redeeming for more than one will be invalid. Please understand.',
        90005: 'Thank you for your continued support. \nI apologize, but your order number is not eligible for the Happy Scratch promotion. (Scratch ineligible: those who enter the contest via tweet / those who require after-sales support). However, please note that we will not be able to accommodate this request again in the future. \nThank you for your understanding.',
    },
    next: 'Next',
    previous: 'Previous',
    done: 'Done',
    submit: 'Submit',
}