module.exports = {
    home: {
        title: 'Congratulazioni! Vinci il premio!'
    },
    redeem: {
        tip1: 'Nota: \n' +
            '①Partecipanti: Clienti che hanno acquistato a proprie spese.\n' +
            '②Ogni cliente può riscattare una sola volta.',
        tip2: 'Iniziamo.\n' +
            'Si prega di inserire il codice di riscatto.',
        codeAlert: 'Inserisci il codice di riscatto corretto.',
    },
    warranty: {
        name: 'Nome:',
        email: 'Email:',
        emailAlert: 'Si prega di inserire un indirizzo email valido.',
        order: 'Numero d\'ordine Amazon:',
        orderTip: 'Esempio: 406-1234567-1234567(17 cifre)',
        orderAlert: 'Inserisci il numero d\'ordine Amazon corretto.',
        otherContactTip: 'Nota: per favore lascia il tuo ID WhatsApp nel caso in cui non possiamo contattarti tramite e-mail.',
        line: 'ID WhatsApp:',
        lineTip: 'Esempio: +39 333 123 1234',
        warningTip: 'Nota: le tue informazioni saranno altamente riservate. Non preoccuparti.'
    },
    finished: {
        title2: 'Grazie per il tuo tempo\n\nTi invieremo il codice della carta regalo Amazon da riscattare una volta visualizzata la tua recensione. Di solito ci vorranno 3-5 giorni.\n\nSi prega gentilmente di controllare la tua email se non la ricevi.',
        dissatisfied: 'Grazie per aver dedicato del tempo a compilare le informazioni.'
    },
    survey: {
        title: 'Sei soddisfatto del nostro prodotto?',
        option1: 'SÌ',
        option2: 'NO',
        dissatisfiedAlert: 'Sei soddisfatto del nostro prodotto?',
        reportTip1: 'Condividi la tua insoddisfazione con noi in modo che possiamo continuare a migliorare il nostro prodotto. Sosteniamo i nostri prodotti e faremo del nostro meglio per risolvere il tuo problema.',
    },
    review: {
        title: 'È il nostro onore.',
        reviewTip1: 'Potresti gentilmente dedicare un po\' di tempo a scrivere una recensione per il nostro prodotto? Allega anche lo screenshot della tua recensione.',
        reviewTip2: 'È lo screenshot della tua pagina di recensione piuttosto che una foto del prodotto.',
        reviewTip3: 'Non includere il gratta e vinci nell\'immagine della recensione.',
        reviewTip4: 'Di seguito sono riportati esempi per riferimento.',
        imageTip: 'Clicca qui per fornire lo screenshot della tua recensione',
        dialog: {
            title: 'Si prega di confermare',
            cancel: 'Errato, inviare nuovamente',
            submit: 'Corretto, sottometti'
        }
    },
    alert: {
        90001: 'Il numero d\'ordine è già esistito.',
    },
    next: 'Prossimo',
    previous: 'Precedente',
    submit: 'Sottoscrivi',
}