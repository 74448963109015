<template>
  <v-card>
    <!--<v-card-title class="text-center">-->
    <!--  {{ $t('reviewFinished.title') }}-->
    <!--</v-card-title>-->
    <v-container fluid>
      <v-row class="mt-3 mb-5 text-center">
        <v-col>
          <span class="text-h5 font-weight-bold">{{ $t('reviewFinished.title') }}</span>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer/>
      <v-btn @click="done" large color="primary" class="mb-3">{{
          $t('done')
        }}
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ReviewFinishedPage",
  computed: {
    ...mapState({
      debug: state => state.debug,
      customer: state => state.review.customer,
    }),
  },
  methods: {
    done() {
      // 关闭浏览器窗口
      window.location.href = "about:blank"
      window.close()
    }
  },
  mounted() {
    if (!this.debug && !this.customer) {
      this.$router.replace("/")
    }
  },
}
</script>

<style scoped>

</style>