import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'US',
    fallbackLocale: 'US',
    messages: {
        US: require('./us.js'),
        DE: require('./de.js'),
        FR: require('./fr.js'),
        ES: require('./es.js'),
        IT: require('./it.js'),
        CA: require('./us.js'),
        UK: require('./uk.js'),
        AU: require('./us.js'),
        JP: require('./jp.js'),
        CN: require('./cn.js')
    }
})

export default i18n