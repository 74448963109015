module.exports = {
    home: {
        title: 'Prämie, Werbegeschenk, Rabatt'
    },
    landing: {
        warranty: '24 Monate Garantie'
    },
    warranty: {
        title: 'Erinnerung: Wir geben Ihre persönlichen Daten nicht an Dritte weiter',
        name: 'Name: ',
        nameAlert: 'Der Name darf 32 Zeichen nicht überschreiten',
        email: 'Post: ',
        emailAlert: 'Die E-Mail-Adresse darf 32 Zeichen nicht überschreiten',
        emailAlert2: 'Bitte geben Sie Ihre gültige E-Mail-Adresse ein',
        order: 'Amazon-Bestellnummer: ',
        orderAlert: 'Bitte geben Sie die richtige Amazon-Bestellnummer ein',
        market: 'Einkaufsplattform: ',
        interested: 'Möchten Sie unseren kostenlosen Test für neue Produkte machen?',
        submit: 'einreichen'
    },
    finished: {
        title: 'Ihr Garantieantrag ist eingegangen, vielen Dank'
    },
    alert: {
        90001: 'Die Amazon-Bestellnummer existiert nicht, bitte geben Sie die richtige Amazon-Bestellnummer ein.',
        90002: 'Bitte wählen Sie die richtige Kaufplattform für die Bestellnummer aus.',
        90003: 'Die Garantie für diese Bestellnummer ist bereits wirksam und muss nicht erneut eingereicht werden.'
    }
}