module.exports = {
    home: {
        title: 'おめでとうございます！アマギフ無料ゲット！'
    },
    landing: {
        warranty: '24ヶ月保証'
    },
    redeem: {
        tip1: 'ご注意：\n' +
            '①参加対象：自分負担買ってしまったお客様\n' +
            '（line/Twitterなどプレゼント当選貰った・99%オフのコードで購入して貰ったお客様が対象以外となります。）\n' +
            '②各コードは 1 回だけ・お一人様は一回のみの引き換えとなります。',
        tip2: '今、始めましょう。\n' +
            '交換コードをご入力してください。',
        code: 'コードナンバー：',
        codeAlert: '正しい交換コードをご入力してください。',
    },
    warranty: {
        name: 'お名前：',
        nameAlert: '名前は32文字を超えてはなりません',
        email: 'メール： ',
        emailAlert: 'メールアドレスは32文字を超えてはなりません',
        emailAlert2: '有効なメールアドレスを入力してください',
        order: 'Amazonの注文番号：',
        orderTip: '例：249-1234567-1234567（17桁）',
        orderAlert: '正しいAmazon注文番号を入力してください',
        market: '購入のサイト：',
        otherContactTip: 'ご注意：弊社よりメールで連絡しますが、受信を拒否されたり、迷惑メールの中に入る可能性があります。アマギフを安全安心に送信できるように、少なくとももう一つの連絡先もご入力してください。（LINE/Twitter/そのほか）',
        line: 'LINE ID：',
        lineTip: '例：tryone_jp',
        twitter: 'TWITTER ID：',
        twitterTip: '例：@LuLuRin_',
        otherContact: 'その他の連絡先：',
        otherContactAlert: '少なくとももう一つの連絡先をご入力してください。',
        warningTip: 'ご注意：上記の個人情報は弊社以外の誰とも共有しません。ご安心ください。'
    },
    finished: {
        title1: 'ご記入いただき誠にありがとうございました。\n\nレビュー内容がアマゾン商品ページで反映されましたら、弊社よりアマゾンギフトコードをお送り致します。約2-7日間かかります。\n\nご注意：プレゼント貰ったお客様が対象以外となります。何卒ご了承ください。',
        title2: '非常に丁寧にお答えをいただき、誠にありがとうございました。\n\nレビュー内容がアマゾン商品ページで反映されましたら、弊社よりアマゾンギフトコードをお送り致します。約2-7日間かかります。\n万が一、メールが届かない場合は、入力いただいたメールアドレスに間違いがあるのか、迷惑メールに入るのかをご確認の上、よろしくお願い申し上げます。\n\nこれからも弊社をご愛顧いただけますようお願い申し上げます。',
        dissatisfied: 'ご記入いただき誠にありがとうございました。\n' +
            'ご提案内容については、社内で十分に検討し、改めてご連絡させていただきます。\n' +
            '\n' +
            '万が一、メールが届かない場合は、入力いただいたメールアドレスに間違いがあるのか、\n' +
            '迷惑メールに入るのかをご確認の上、よろしくお願い申し上げます。\n' +
            '\n' +
            'これからも弊社をご愛顧いただけますようお願い申し上げます。'
    },
    trial: {
        title: 'ちなみに、弊社は不定期に無料新品試用キャンペンを開催しますので、ご興味のある無料試用品を以下の項目からお選びください。（複数選択）',
        trialAlert: '少なくとも一つを選んでください',
        submit: '以上の商品に興味があります',
        cancel: 'すべて興味がないです'
    },
    trialFinished: {
      title: '非常に丁寧にお答えをいただき、誠にありがとうございました。',
        tip: 'ご興味のある無料試用品の募集キャンペンが開催してから、弊社よりご連絡させていただきます。\n別に具体的な流れをご案内させていただきます。\n' +
            '\n' +
            '万が一、メールが届かない場合は、入力いただいたメールアドレスに間違いがあるのか、\n迷惑メールに入るのかをご確認の上、よろしくお願い申し上げます。\n\n' +
            'これからも弊社をご愛顧いただけますようお願い申し上げます。'
    },
    survey: {
        title: 'ご購入いただいた製品にご満足していますか。',
        option1: 'はい',
        option2: 'いいえ',
        dissatisfiedAlert: 'ご購入いただいた製品にご満足しているのかを選択してください。',
        reportTip1: 'ご購入いただいた製品に対して総合的にどのくらい満足していますか。何か問題点、ご意見・ご要望がございましたら、ご自由にお書きください。',
        reportTip2: '弊社は継続的に満足度を聞くことで、商品の健康状態を常に把握できることに加え、ご意見やご要望を自由に入力してもらうことで、商品の改善ポイントを発見させて頂きます。',
        reportAlert: '何か問題点があれば、ご遠慮なく教えてくださいませんか。'
    },
    review: {
        title: 'お手数をおかけしますが、ご協力いただければ幸いです。',
        reviewTip1: 'つきまして、下記の手順通りにアマゾンでレビューをご投稿お願い申し上げます。',
        reviewTip1_1: '①Amazonを開いて、ログインする\n②【注文履歴】をタップする\n③レビューを書きたい商品を選択する\n④【商品レビューを書く】をタップする\n⑤総合評価（星5必要）、項目別評価（星5必要）、レビュータイトルを入力、画像・動画を添付、レビューを追加して【投稿】をタップする',
        reviewWarning: 'ご注意：',
        reviewWarning1: 'レビュー内容の中にスクラッチカードの写真を添付しないでください。',
        reviewWarning2: '下記のスクリーンショットは製品の写真ではなく、上記製品のレビュー内容をご添付してください。',
        // reviewTip2: 'ご注意：スクリーンショットは製品の写真ではなく、製品のレビュー内容をご提出ください。',
        // reviewTip3: 'レビュー内容の中にスクラッチカードの写真を添付しないでください。',
        // reviewTip4: '最近、投稿したレビューが削除されてしまうことがあるので、アカウントの安全を保護するため、商品を受け取ったら一週間の程で使用感想をアマゾンでレビューを投稿お願い申し上げます。',
        imageTip: '上記例1~3のようなレビュー内容を添付してください。',
        imageAlert: '製品の写真のみを提出しないでくさい。レビュー内容のスクリーンショットが必要です。',
        dialog: {
            title: 'ご注意',
            tip1: 'スクリーンショットは製品の写真ではなく、{0}をご確認ください。',
            tip2: '製品のレビュー内容',
            cancel: 'いいえ、再度提出',
            submit: 'はい'
        }
    },
    alert: {
        90001: 'こちらの注文番号はもう記入されました。正しい注文番号を入力してください。',
        90002: 'Please choose the correct marketplace.',
        90003: 'こちらの注文番号は既に提出されました。弊社より審査していおりますのでもう少しお待ちください。レビュー内容がアマゾン商品ページで反映されましたら、弊社より約2-7日間アマゾンギフトコードをお送り致します。何卒ご了承ください。',
        90004: '申し訳ございませんが、同じアカウント/一人様は一回限りです。既にアマギフを引き換えたことがありますので、複数に引き換えることが無効になります。何卒ご了承ください。'
    },
    next: '次へ',
    previous: '戻る',
    submit: '完了',
}