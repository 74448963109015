<template>
  <v-card>
    <v-container fluid v-if="dissatisfied">
      <v-row>
        <v-col>
          <p class="font-weight-bold text-pre-wrap">{{ $t('finished.dissatisfied') }}</p>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-else>
      <v-row>
        <v-col>
          <p v-if="ignoreFlag" class="font-weight-bold text-pre-wrap">{{ $t('finished.title2') }}</p>
          <p v-else class="font-weight-bold text-pre-wrap">{{ $t('finished.title1') }}</p>
        </v-col>
      </v-row>
      <v-divider/>
      <v-row class="mt-5" v-if="!ignoreFlag">
        <v-col>
          <p class="font-weight-bold">{{ $t('trial.title') }}</p>
        </v-col>
      </v-row>
      <v-row class="mt-5 text-center" v-else>
        <v-col>
          <v-btn class="mr-5" color="warning" large @click="reset">{{ $t('previous') }}</v-btn>
        </v-col>
      </v-row>
      <v-container v-if="!ignoreFlag">
        <v-form ref="form" lazy-validation>
          <v-row>
            <trial-item :items="trialItems['JP']" v-model="trials" text="key" value="key" :multi="true"/>
          </v-row>
          <v-row class="text-center mt-12 mb-3">
            <v-col>
              <v-btn class="mr-5" color="warning" large @click="cancel" :loading="loading">{{ $t('trial.cancel') }}</v-btn>
              <v-btn color="primary" large @click="submit" :loading="loading">{{ $t('trial.submit') }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-container>
  </v-card>
</template>

<script>
import TrialItem from "@/components/TrialItem";
import {mapActions, mapState} from "vuex";

export default {
  name: "FinishedPage",
  components: {TrialItem},
  computed: {
    trials: {
      get() {
        return this.$store.state.review.trials
      },
      set(val) {
        this.$store.commit("review/TRIALS", val)
      }
    },
    ...mapState({
      debug: state => state.debug,
      customer: state => state.review.customer,
      dissatisfied: state => state.review.dissatisfied,
    })
  },
  data() {
    return {
      loading: false,
      ignoreFlag: true,
      trialItems: {
        "JP": [/*
          {
            key: "Bluetoothイヤホン",
            imageUrl: require("@/assets/product/item1.png")
          },*/
          /*{
            key: "ワイヤレスイヤホン",
            imageUrl: require("@/assets/product/item2.jpeg")
          },*/
          /*{
            key: "完全ワイヤレスイヤホン",
            imageUrl: require("@/assets/product/item8.jpeg")
          },*/
          /*{
            key: "シャワーヘッド",
            imageUrl: require("@/assets/product/item10.jpeg")
          },*/
          /*{
            key: "安眠枕",
            imageUrl: require("@/assets/product/item7.jpeg")
          },*/
          /*{
            key: "車載ホルダー",
            imageUrl: require("@/assets/product/item3.jpeg")
          },*/
          /*{
            key: "折りたたみ傘",
            imageUrl: require("@/assets/product/item4.jpeg")
          },*/
          /*{
            key: "シャワーヘッド",
            imageUrl: require("@/assets/product/item5.jpeg")
          },*/
          /*{
            key: "Bluetoothヘッドセット",
            imageUrl: require("@/assets/product/item6.jpeg")
          },*/
          /*{
            key: "伸縮スマホ/タブレットスタンド",
            imageUrl: require("@/assets/product/item9.jpeg")
          },*/
          /*{
            key: "Bluetooth イヤホン（Q81）",
            imageUrl: require("@/assets/product/item11.jpeg")
          },*/
          /*{
            key: "Bluetoothイヤホン （H39）",
            imageUrl: require("@/assets/product/item12.jpeg")
          },*/
          /*{
            key: "車載ホルダー",
            imageUrl: require("@/assets/product/item13.jpeg")
          },*/
          {
            key: "Bluetooth イヤホン（Y62）",
            imageUrl: require("@/assets/product/item14.jpeg")
          },
          {
            key: "Bluetoothヘッドセット(YYK-525)",
            imageUrl: require("@/assets/product/item15.jpeg")
          },
          {
            key: "Bluetooth イヤホン（M48）",
            imageUrl: require("@/assets/product/item16.jpeg")
          },
        ]
      },
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.submitTrials().then(() => {
          this.loading = false
          this.$router.replace('/trial/finished')
        }, () => {
          this.loading = false
        })
      }
    },
    cancel() {
      this.ignoreFlag = true
    },
    reset() {
      this.ignoreFlag = true
    },
    ...mapActions({
      submitTrials: "review/submitTrials"
    })
  },
  mounted() {
    if (!this.debug && !this.customer) {
      this.$router.replace("/")
    }
  },
}
</script>

<style scoped>

</style>